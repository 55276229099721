import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import './ChatMessages.scss'
import { Chat, ChatMessage } from './chatsSlice'
import { ChatWebSocketConnectionContext } from '../WebsocketConnection/ChatWebSocketConnectionContext'
import { MdOutlineAddReaction } from 'react-icons/md'
import { EMOJI_STYLE } from './EmojiPickerModal'
import ChatReactionsBubbles from './ChatReactionsBubbles'

export default function ChatReactions({
  message,
  chat,
  citation,
  openEmojiPickerModal,
}: {
  message: ChatMessage
  chat: Chat
  citation?: true
  openEmojiPickerModal: (e: React.MouseEvent<HTMLElement>, messageId: string) => void
}) {
  const ws = useContext(ChatWebSocketConnectionContext)
  const invite = useSelector((state: RootState) => state.invite)
  const username = useSelector((state: RootState) => state.auth.email || invite.email)

  const addReaction = (emoji: string, messageId: string, chatId: string) => {
    ws?.sendMessageReactionAdded(chatId, messageId, emoji)
  }

  const removeReaction = (reactionId: string) => {
    ws?.sendMessageReactionRemoved(reactionId)
  }

  return (
    <>
      {!citation && message.reactions && message.reactions.length > 0 && (
        <div className={'chat_message_reaction_container'}>
          <ChatReactionsBubbles
            ownEmail={username || ''}
            reactions={message.reactions}
            style={EMOJI_STYLE}
            addReaction={(emoji) => addReaction(emoji, message.id, chat.id)}
            removeReaction={removeReaction}
          />

          <div
            className="chat_message_reaction_bubble"
            onClick={(e) => openEmojiPickerModal(e, message.id)}
          >
            <MdOutlineAddReaction size="15px" />
          </div>
        </div>
      )}
    </>
  )
}
