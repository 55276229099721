import React from 'react'
import { Button, Card, Col, Row } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { BiLogInCircle } from 'react-icons/bi'
import { BsCardChecklist } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from '../../store'
import { LogoutOutlined } from '@ant-design/icons'
import { logout } from '../auth/redux/authSlice'
import './Homepage.scss'
import { LanguageSwitch } from '../../components/LanguageSwitch'
import { authLogoFilename } from '../../assets/personnalization'

function Homepage() {
  const dispatch = useDispatch<AppDispatch>()
  const { i18n, t } = useTranslation('homepage')
  const email = useSelector((state: RootState) => state.auth.email)
  const currentYear = new Date().getFullYear()

  function logoutHandler() {
    dispatch(logout())
  }

  return (
    <div className="Homepage">
      <div className="header">
        <LanguageSwitch />
        {email ? (
          <>
            <Link to="/upcoming-meetings" className="ml-1rem login-link">
              <BsCardChecklist size="2em" /> {t('My meetings list')}
            </Link>
            <Button className="ml-1rem" type="primary" onClick={logoutHandler}>
              <LogoutOutlined /> {t('Log out')}
            </Button>
          </>
        ) : (
          <Link to="/login" className="ml-1rem login-link">
            <BiLogInCircle size="2em" /> <span>{t('Log in')}</span>
          </Link>
        )}
      </div>
      <div className="presentation">
        <img className="logo" src={`/logo/${authLogoFilename}`} />
        <h1>Auxamed</h1>
      </div>

      <div className="liscense_pricing">
        <div className="liscense_pricing_container">
          <h3 className="liscense_pricing_title">{t('Pricing')}</h3>
          <Row gutter={24} className="liscense_pricing_row">
            <Col span={8}>
              <Card
                title={
                  <div className="liscense_pricing_card_title">
                    <span className="liscense_pricing_card_title_text">
                      {t('Minimalist')}
                    </span>
                    <span className="liscense_pricing_card_title_price">
                      <Trans
                        i18nKey="PACKAGE_PRICE"
                        shouldUnescape={true}
                        ns="homepage"
                        values={{ price: '39€' }}
                      />
                    </span>
                  </div>
                }
                hoverable
                actions={[
                  <Button type="default" key={'price'}>
                    {t('Order')}
                  </Button>,
                ]}
              >
                <p>
                  <Trans
                    i18nKey="COUNT_MEETINGS"
                    shouldUnescape={true}
                    ns="homepage"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_COLLABORATORS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ count: 10 }}
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_INVITATIONS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ count: 100 }}
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_EXTRA_INVITATIONS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ price: '20€' }}
                    components={{ bold: <strong /> }}
                  />
                </p>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                className="liscense_pricing_card_proeminent"
                title={
                  <div className="liscense_pricing_card_title">
                    <span className="liscense_pricing_card_title_text">
                      {t('Intensive')}
                    </span>
                    <span className="liscense_pricing_card_title_price">
                      <Trans
                        i18nKey="PACKAGE_PRICE"
                        shouldUnescape={true}
                        ns="homepage"
                        values={{ price: '99€' }}
                      />
                    </span>
                  </div>
                }
                hoverable
                actions={[
                  <Button type="primary" key={'order'}>
                    Order
                  </Button>,
                ]}
              >
                <p>
                  <Trans
                    i18nKey="COUNT_MEETINGS"
                    shouldUnescape={true}
                    ns="homepage"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_COLLABORATORS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ count: 50 }}
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_INVITATIONS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ count: 500 }}
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_EXTRA_INVITATIONS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ price: '15€' }}
                    components={{ bold: <strong /> }}
                  />
                </p>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title={
                  <div className="liscense_pricing_card_title">
                    <span className="liscense_pricing_card_title_text">
                      {t('Significant')}
                    </span>
                    <span className="liscense_pricing_card_title_price">
                      <Trans
                        i18nKey="PACKAGE_PRICE"
                        shouldUnescape={true}
                        ns="homepage"
                        values={{ price: '159€' }}
                      />
                    </span>
                  </div>
                }
                hoverable
                actions={[
                  <Button type="default" key={'order'}>
                    Order
                  </Button>,
                ]}
              >
                <p>
                  <Trans
                    i18nKey="COUNT_MEETINGS"
                    shouldUnescape={true}
                    ns="homepage"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_COLLABORATORS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ count: 150 }}
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_INVITATIONS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ count: 1500 }}
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="COUNT_EXTRA_INVITATIONS"
                    shouldUnescape={true}
                    ns="homepage"
                    values={{ price: '10€' }}
                    components={{ bold: <strong /> }}
                  />
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <footer>
        <div>
          Auxamed {currentYear} -&nbsp;
          <a
            className="footer-document"
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_STATIC_ASSETS_BASE_URL}/legal-notice_${i18n.language}.pdf`}
          >
            {t('Legal notice', { ns: 'common' })}
          </a>
          <span> - </span>
          <a
            className="footer-document"
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_STATIC_ASSETS_BASE_URL}/cgu_${i18n.language}.pdf`}
          >
            {t('Terms and conditions of service', { ns: 'common' })}
          </a>
        </div>
      </footer>
    </div>
  )
}

export default Homepage
