import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSound from 'use-sound'
import sanitizeHtml from 'sanitize-html'
import { useNavigate } from 'react-router-dom'
import { setCurrentChatId } from '../ChatComponent/Chat/chatsSlice'
import { AppDispatch, RootState } from '../../store'
import sound from './assets/notifications.mp3'
import { LOGO } from '../../assets/logos'

interface NotificationMisc {
  senderFirstName?: string
  senderLastName?: string
  text?: string
  [key: string]: any
}

export function useLiveNotifications() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const unseen = useSelector((state: RootState) => state.liveNotification.unseen)

  const notifications = useSelector(
    (state: RootState) => state.liveNotification.notifications,
  )
  const desktopNotificationState = useSelector(
    (state: RootState) => state.account.IsDesktopNotificationEnabled,
  )

  const [play] = useSound(sound, { interrupt: true })
  const [oldUnseen, setOldUnseen] = useState(unseen)

  useEffect(() => {
    if (unseen !== oldUnseen) {
      if ((unseen as number) > (oldUnseen as number)) {
        play()
        sendDesktopNotification()
      }
      setOldUnseen(unseen)
    }
  }, [unseen])

  function sendDesktopNotification() {
    if (!desktopNotificationState) return

    let latestTimestamp = 0
    let lastReceivedNotification: NotificationMisc | undefined

    notifications.forEach((notification) => {
      const notificationDate = new Date(notification?.createdAt ?? '')
      if (notificationDate.getTime() > latestTimestamp) {
        latestTimestamp = notificationDate.getTime()
        lastReceivedNotification = notification.misc
      }
    })

    if (lastReceivedNotification?.senderFirstName) {
      const notification = new Notification(
        `${lastReceivedNotification.senderFirstName} ${lastReceivedNotification.senderLastName || ''}`,
        {
          body: sanitizeHtml(
            lastReceivedNotification.text || 'No text detected in message',
            {
              allowedTags: [],
            },
          ),
          icon: LOGO.logo_auxateam,
        },
      )

      notification.onclick = () => {
        dispatch(setCurrentChatId(lastReceivedNotification?.chatId))
        navigate('/messages')
      }
    }
  }

  return { unseen, notifications }
}
