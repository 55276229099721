import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, MenuProps, Popover, Spin, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useAttemptsListener } from 'auxasphere-react-kit'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'
import { AiOutlineEdit, AiOutlineEye } from 'react-icons/ai'
import { IoTrashOutline } from 'react-icons/io5'
import { MdOutlineGroupOff } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import ModalConfirmation, {
  ModalConfirmationInterface,
} from '../../../components/Modal/ModalConfirmation'
import { AppDispatch, RootState } from '../../../store'
import { limitText } from '../../../utils/Utils'
import UpdateGroupeModal from '../UpdateGroupModal'
import {
  Group,
  deleteGroup,
  fetchGroupParticipants,
  fetchGroups,
  idleDeleteGroupStatus,
  setGroupToModify,
} from '../redux/groupsSlice'
import './groups-list.scss'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useToastContext } from '../../../components/Toast/ToastContext'
import { OrganizationRole } from '../../Organisation/OrganizationInterface'

function GroupsList() {
  const { t } = useTranslation('groups')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const deleteGroupModalConfirmation = useRef<ModalConfirmationInterface>(null)
  const groups = useSelector((state: RootState) => state.groups.groups)
  const organisation = useSelector((state: RootState) => state.organisation)

  const fetchGroupsStatus = useSelector(
    (state: RootState) => state.groups.fetchGroupsStatus,
  )
  const deleteGroupStatus = useSelector(
    (state: RootState) => state.groups.deleteGroupStatus,
  )

  const [groupToDelete, setGroupToDelete] = useState<Group>()

  const isOrganizationAdmin = useSelector(
    (state: RootState) => state.auth.organizationRole === OrganizationRole.ADMIN,
  )

  useAttemptsListener([
    [
      deleteGroupStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Group was successfully deleted.'),
            type: 'success',
          })
          setGroupToDelete(undefined)
          dispatch(fetchGroups())
          deleteGroupModalConfirmation.current?.close()
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error deleting group.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleDeleteGroupStatus()),
    ],
  ])

  useEffect(() => {
    dispatch(fetchGroups())
  }, [])

  const menuItems = (group: Group, isOrganizationAdmin: boolean) => {
    var menu: MenuProps['items'] = []
    menu.push({
      key: 'view',
      label: (
        <div className="cursor-pointer link d-flex d-flex-middle">
          <AiOutlineEye size="1.1rem" />
          <div className="dropdown-icon-margin-left">{t('View group')}</div>
        </div>
      ),
      onClick: () => dispatch(setGroupToModify({ group, readonly: true })),
    })
    if (isOrganizationAdmin) {
      menu.push(
        {
          key: 'edit',
          label: (
            <div className="cursor-pointer link d-flex d-flex-middle">
              <AiOutlineEdit size="1.1rem" />
              <div className="dropdown-icon-margin-left">{t('Modify group')}</div>
            </div>
          ),
          onClick: () => dispatch(setGroupToModify({ group, readonly: false })),
        },
        {
          type: 'divider',
        },
        {
          key: 'delete',
          label: (
            <div className="cursor-pointer d-flex d-flex-middle bin-button">
              <IoTrashOutline size="1.1rem" className="error-color" />
              <div className="dropdown-icon-margin-left error-color">
                {t('Delete group')}
              </div>
            </div>
          ),
          onClick: () => onDeleteGroup(group),
        },
      )
    }
    return menu
  }

  const columns: ColumnsType<Group> = [
    {
      title: t('Group name'),
      dataIndex: 'name',
      className: 'name-column',
    },
    {
      title: t('Creator', { ns: 'common' }),
      className: 'creator-column',
      render: (_, group: Group) => {
        return group.creatorFirstName && group.creatorLastName ? (
          <Tooltip title={group.creatorEmail}>
            <span>{`${group.creatorFirstName} ${group.creatorLastName}`}</span>
          </Tooltip>
        ) : (
          <span>{group.creatorEmail}</span>
        )
      },
    },
    {
      title: t('Creation date', { ns: 'common' }),
      className: 'create_dt-column',
      dataIndex: 'createdAt',
      render: (_, group: Group) => dayjs(group.createdAt).format('LLL'),
    },
    {
      title: t('Users', { ns: 'room' }),
      className: 'cursor-pointer',
      render: (_, group: Group) => (
        <Popover
          trigger={'hover'}
          className="w-min"
          onOpenChange={(visible) => {
            if (!visible || !organisation.organisation?.id || group.participants) return
            dispatch(
              fetchGroupParticipants({
                organizationId: organisation.organisation.id,
                groupId: group.id,
              }),
            )
          }}
          content={() => (
            <div className="d-flex d-flex-column overflow-scroll mh-12rem pr-05rem w-13rem">
              <b>{t('Creator', { ns: 'common' })}</b>
              <ul>
                <li key={group.creatorEmail}>
                  {`${group.creatorFirstName} ${group.creatorLastName}` ||
                    group.creatorEmail}
                </li>
              </ul>
              <b>{t('Users', { ns: 'room' })}</b>
              <ul>
                {group.participants ? (
                  group.participants?.map((p) => (
                    <li key={p.email}>
                      {p.firstName ? (
                        <>{`${p.firstName} ${p.lastName}`}</>
                      ) : (
                        <>{p.email}</>
                      )}
                    </li>
                  ))
                ) : (
                  <Spin />
                )}
              </ul>
            </div>
          )}
        >
          {group.participantsCount}
        </Popover>
      ),
    },
    {
      title: '',
      className: 'action-column',
      render: (_, group: Group) => {
        return (
          <div className="action-main-container">
            <div className="action-custom-container">
              <div className="action-hover-container">
                <div className="action-icons">
                  <div
                    className="action-rounded-icon cursor-pointer d-flex d-flex-middle"
                    onClick={() => {
                      dispatch(setGroupToModify({ group, readonly: true }))
                    }}
                  >
                    <Tooltip title={t('View group')} className="cursor-pointer">
                      {<AiOutlineEye size="1.5rem" />}
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <Dropdown
              menu={{ items: menuItems(group, isOrganizationAdmin || false) }}
              className="action-more"
              trigger={['click']}
            >
              <div className="action-more-container">
                <div className="action-more">
                  <BiDotsVerticalRounded size="1.5em" />
                </div>
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  /**
   *
   */
  function onCancel() {
    setGroupToDelete(undefined)
  }

  /**
   *
   */
  function confirmDeleteGroup() {
    if (groupToDelete && groupToDelete.id) {
      dispatch(deleteGroup({ groupId: groupToDelete.id }))
    }
  }

  /**
   *
   */
  function onDeleteGroup(group: Group) {
    setGroupToDelete(group)
    deleteGroupModalConfirmation.current?.openModal()
  }

  return (
    <>
      {fetchGroupsStatus === 'loading' && (
        <div className="d-flex d-flex-center d-flex-middle 2rem">
          <Spin size="large" />
        </div>
      )}
      {fetchGroupsStatus === 'success' &&
        (groups && groups?.length > 0 ? (
          <Table
            columns={columns}
            dataSource={groups}
            rowKey="name"
            pagination={
              groups.length >= 21 && {
                defaultPageSize: 20,
                position: ['bottomCenter'],
                showTotal: (total, range) => (
                  <Trans
                    ns="common"
                    i18nKey="TABLE_PAGINATION"
                    values={{
                      range0: range[0],
                      range1: range[1],
                      total: total,
                    }}
                  />
                ),
                showSizeChanger: true,
              }
            }
          />
        ) : (
          <div className="Drives-empty-container">
            <MdOutlineGroupOff size="2.5em" />
            <h3>{t("You don't currently have any group.")}</h3>
          </div>
        ))}

      <ModalConfirmation
        ref={deleteGroupModalConfirmation}
        title={t('Delete group')}
        onOk={confirmDeleteGroup}
        okText={t('Yes', { ns: 'common' })}
        confirmLoading={deleteGroupStatus === 'loading'}
        onCancel={onCancel}
        cancelText={t('No', { ns: 'common' })}
      >
        <p>
          <Trans
            i18nKey={'DELETE_GROUP'}
            shouldUnescape={true}
            ns="groups"
            values={{ name: limitText(groupToDelete?.name) }}
            components={{ bold: <strong /> }}
          />
        </p>
      </ModalConfirmation>
      <UpdateGroupeModal />
    </>
  )
}

export default GroupsList
