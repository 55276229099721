import React, { MutableRefObject, useEffect, useState } from 'react'
import { BiSignal1, BiSignal2, BiSignal3, BiSignal4, BiSignal5 } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { ConsumerModel, ProducerModel } from '../features/room/redux/model'
import RoomClient from '../features/room/RoomClient'
import './CallQualityIndicator.scss'
import { useTranslation } from 'react-i18next'
import { IoCloseOutline } from 'react-icons/io5'
interface StatsState {
  audioScore?: number
  videoScore?: number
}

interface Props {
  audioProducer?: ProducerModel
  audioConsumer?: ConsumerModel
  videoProducer?: ProducerModel
  videoConsumer?: ConsumerModel
  isMe: boolean
  roomClient: MutableRefObject<RoomClient>
}

const AUDIO_THRESHOLDS = {
  highBitrate: 50, // kbps
  midHighBitrate: 30,
  midBitrate: 20,
  lowBitrate: 10,
}

const VIDEO_THRESHOLDS = {
  highBitrate: 1500, // kbps
  midHighBitrate: 1200,
  midBitrate: 800,
  lowBitrate: 400,
}

export default function CallQualityIndicator({
  audioProducer,
  audioConsumer,
  videoProducer,
  videoConsumer,
  isMe,
  roomClient,
}: Props) {
  const [stats, setStats] = useState<StatsState>({
    audioScore: 0,
    videoScore: 0,
  })
  const { t } = useTranslation('room')

  useEffect(() => {
    const interval = setInterval(() => {
      getStats()
    }, 3000)

    return () => clearInterval(interval)
  }, [audioConsumer, videoConsumer, isMe, roomClient])

  /**
   *
   */
  async function getStats() {
    let updatedStats = { ...stats }

    if (isMe) {
      const audioStats = await roomClient.current
        .getAudioRemoteStats()
        .catch(() => undefined)

      const videoStats = await roomClient.current
        .getVideoRemoteStats()
        .catch(() => undefined)

      updatedStats.audioScore = getQualityScore('audio', audioStats)
      updatedStats.videoScore = getQualityScore('video', videoStats)
    } else {
      const audioStats = await roomClient.current
        .getConsumerRemoteStats(audioConsumer?.id)
        .catch(() => undefined)
      const videoStats = await roomClient.current
        .getConsumerRemoteStats(videoConsumer?.id)
        .catch(() => undefined)

      updatedStats.audioScore = getQualityScore('audio', audioStats)
      updatedStats.videoScore = getQualityScore('video', videoStats)
    }

    setStats(updatedStats)
  }

  /**
   * Calculates a quality score for a WebRTC audio connection based on bitrate, jitter, and packet loss.
   *
   * Scoring mechanism:
   * - Bitrate: A higher bitrate contributes positively to the score. If the bitrate is low but jitter
   *   and packet loss are within acceptable limits, it is considered stable despite low activity.
   * - Jitter: High jitter negatively impacts the score, as it indicates inconsistent data delivery.
   * - Packet Loss: High packet loss significantly reduces the score, as it impacts data integrity.
   *
   * Scores range from 0 (poor quality) to 10 (excellent quality).
   *
   * @param {number} bitrate - The audio connection bitrate in kbps.
   * @param {number} jitter - The jitter value (in milliseconds) for the audio connection.
   * @param {number} packetLoss - The packet loss percentage for the audio connection.
   * @returns {number} - The calculated quality score (0 to 10).
   */
  function calculateAudioScore(
    bitrate: number,
    jitter: number,
    packetLoss: number,
  ): number | undefined {
    const thresholds = AUDIO_THRESHOLDS
    if (bitrate < 1.5) return undefined

    // Check if bitrate is low but other metrics are correct
    if (bitrate < thresholds.lowBitrate) {
      if (packetLoss < 2 && jitter < 100) {
        return 8 // Connectivité stable mais faible activité
      }
    }

    if (bitrate > thresholds.highBitrate) {
      return 10
    } else if (bitrate > thresholds.midHighBitrate) {
      return 8
    } else if (bitrate > thresholds.midBitrate) {
      return 6
    } else if (bitrate > thresholds.lowBitrate) {
      return 4
    } else {
      return 2
    }
  }

  /**
   * Calculates a quality score for a WebRTC video connection based on bitrate.
   *
   * Scoring mechanism:
   * - Bitrate: A higher bitrate contributes positively to the score, indicating better video quality.
   *   Lower bitrates are penalized, as they result in reduced video clarity and stability.
   *
   * Scores range from 0 (poor quality) to 10 (excellent quality).
   *
   * @param {number} bitrate - The video connection bitrate in kbps.
   * @returns {number | undefined} - The calculated quality score (0 to 10), or undefined if bitrate is 0.
   */
  function calculateVideoScore(bitrate: number): number | undefined {
    const thresholds = VIDEO_THRESHOLDS
    if (bitrate === 0) return undefined

    if (bitrate > thresholds.highBitrate) {
      return 10
    } else if (bitrate > thresholds.midHighBitrate) {
      return 8
    } else if (bitrate > thresholds.midBitrate) {
      return 6
    } else if (bitrate > thresholds.lowBitrate) {
      return 4
    } else {
      return 2
    }
  }

  /**
   *
   * @param mediaType
   * @param stats
   * @returns
   */
  function getQualityScore(
    mediaType: 'audio' | 'video',
    stats?: Object[],
  ): number | undefined {
    if (stats) {
      const latestStats = stats[stats.length - 1] as any

      if (latestStats.type === 'inbound-rtp') {
        const bitrateKbps = latestStats.bitrate / 1000
        const jitter = latestStats.jitter
        const rtt = latestStats.rtt
        const packetLosts = latestStats.packetsLost

        if (mediaType === 'audio') {
          return calculateAudioScore(bitrateKbps, jitter, packetLosts)
        } else if (mediaType === 'video') {
          return calculateVideoScore(bitrateKbps)
        }
      }
    }
    return undefined
  }

  return (
    <div className="CallQualityIndicator">
      <h4 className="white">{t('Call quality')}</h4>

      <div className="row">
        <p className="row-title">{t('Audio')}</p>
        {stats.audioScore ? (
          <>
            {stats.audioScore <= 2 && <BiSignal1 size={25} color="white" />}
            {stats.audioScore > 2 && stats.audioScore <= 4 && (
              <BiSignal2 size={25} color="white" />
            )}
            {stats.audioScore > 4 && stats.audioScore <= 6 && (
              <BiSignal3 size={25} color="white" />
            )}
            {stats.audioScore > 6 && stats.audioScore <= 8 && (
              <BiSignal4 size={25} color="white" />
            )}
            {stats.audioScore > 8 && stats.audioScore <= 10 && (
              <BiSignal5 size={25} color="white" />
            )}
          </>
        ) : (
          <IoCloseOutline size={25} color="white" />
        )}
      </div>

      <div className="row">
        <p className="row-title">{t('Video')}</p>
        {stats.videoScore ? (
          <>
            {stats.videoScore <= 2 && <BiSignal1 size={25} color="white" />}
            {stats.videoScore > 2 && stats.videoScore <= 4 && (
              <BiSignal2 size={25} color="white" />
            )}
            {stats.videoScore > 4 && stats.videoScore <= 6 && (
              <BiSignal3 size={25} color="white" />
            )}
            {stats.videoScore > 6 && stats.videoScore <= 8 && (
              <BiSignal4 size={25} color="white" />
            )}
            {stats.videoScore > 8 && stats.videoScore <= 10 && (
              <BiSignal5 size={25} color="white" />
            )}
          </>
        ) : (
          <IoCloseOutline size={25} color="white" />
        )}
      </div>
    </div>
  )
}
