import React, { useState } from 'react'
import { Alert, Button, Dropdown, MenuProps, Popover, Spin, Table, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  RoomInterface,
  RoomParticipant,
  RoomParticipantRoleEnum,
  RoomType,
} from '../room/RoomInterface'
import { Trans, useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import './meetings.scss'
import { useNavigate } from 'react-router-dom'
import {
  AiOutlineEye,
  AiOutlinePaperClip,
  AiOutlineUser,
  AiOutlineVideoCameraAdd,
  AiOutlineEdit,
} from 'react-icons/ai'
import { IoTrashOutline } from 'react-icons/io5'
import { generateRoom, idleGenerateRoomStatus, setRoomToDelete } from './MeetingsSlice'
import { localFormatDate, hasAccessFiles } from '../../utils/Utils'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useAttemptsListener } from 'auxasphere-react-kit'
import humanizeDuration from 'humanize-duration'
import { unescape } from 'html-escaper'
import { SlPhone } from 'react-icons/sl'
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2'
import { RiComputerLine } from 'react-icons/ri'
import { fetchFileMetadata } from '../../components/Viewer/ViewerSlice'
import { setDuplicateMeeting } from './NewMeetingSlice'
import { CgDuplicate } from 'react-icons/cg'
import { useToastContext } from '../../components/Toast/ToastContext'
import { ServiceUnavailableError } from '../../components/ServiceUnavailableError'
import useModalDeleteMeeting from '../../components/Modal/useModalDeleteMeeting'

interface Props {
  archived: boolean
  data: {
    rooms: RoomInterface[]
    fetchStatus: string
    fetchError?: string | undefined
  }
  setRoomModify: (room: RoomInterface) => void
}

export default function MeetingsTable(props: Props) {
  const { archived, data, setRoomModify } = props
  const { t, i18n } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const navigate = useNavigate()
  const email = useSelector((state: RootState) => state.auth.email)
  const [navigationForward, setNavigationForward] = useState<
    (room: RoomInterface) => void
  >((room: RoomInterface) => {})
  const generateRoomStatus = useSelector(
    (state: RootState) => state.rooms.generateRoomStatus,
  )
  const roomFetched = useSelector((state: RootState) => state.rooms.roomFetched)
  const customMeetingInteraction = (
    room: RoomInterface,
    callback: (room: RoomInterface) => void,
  ) => {
    if (room.id) {
      callback(room)
    } else {
      setNavigationForward(() => callback)
      dispatch(generateRoom(room))
    }
  }

  useAttemptsListener([
    [
      generateRoomStatus,
      {
        success: () => {
          navigationForward(roomFetched as RoomInterface)
        },
        error: () =>
          ToastOpen({
            message: t('Error retrieving meeting information.'),
            type: 'error',
          }),
      },
      () => dispatch(idleGenerateRoomStatus()),
    ],
  ])
  const { openConfirmModal } = useModalDeleteMeeting()
  const duplicateAndEditMeeting = (room: RoomInterface) => {
    dispatch(setDuplicateMeeting(room))
    navigate('/new-meeting')
  }

  const menuItems = (room: RoomInterface) => {
    var menu: MenuProps['items'] = []
    if (!archived) {
      menu.push({
        key: 'join',
        label: (
          <div className="cursor-pointer link d-flex d-flex-middle">
            <AiOutlineVideoCameraAdd size="1.1rem" />
            <div className="dropdown-icon-margin-left">{t('Join meeting')}</div>
          </div>
        ),
        onClick: () =>
          customMeetingInteraction(room, (room: RoomInterface) =>
            navigate(`/room/${room.id}`),
          ),
        className: 'meetings-dropdown-item',
      })
    }

    menu = menu.concat([
      // {
      //   key: 'notarisation',
      //   label: (
      //     <div className="cursor-pointer d-flex d-flex-middle">
      //       <MdOutlineNoteAlt size="1.1rem" className="default-text-color" />
      //       <div className="dropdown-icon-margin-left">{t('Notarisation')}</div>
      //     </div>
      //   ),
      //   onClick: () =>
      //     navigateCustom(room, (room: RoomInterface) => navigate(`/upcoming-meetings/notarisation/${room.id}?name=${room.name}`)}),
      // },
      {
        key: 'visualise-modify',
        label: (
          <div className="cursor-pointer d-flex d-flex-middle">
            {(room.creator ? room.creator.email !== email || archived : false) ? (
              <>
                <AiOutlineEye size="1.1rem" className="default-text-color" />
                <div className="dropdown-icon-margin-left">{t('Visualise meeting')}</div>
              </>
            ) : (
              <>
                <AiOutlineEdit size="1.1rem" className="default-text-color" />
                <div className="dropdown-icon-margin-left">{t('Modify meeting')}</div>
              </>
            )}
          </div>
        ),
        onClick: () => customMeetingInteraction(room, (room) => setRoomModify(room)),
        className: 'meetings-dropdown-item',
      },
      room.creator?.email === email
        ? {
            key: 'duplicate',
            label: (
              <div className="cursor-pointer d-flex d-flex-middle">
                <>
                  <CgDuplicate size="1.1rem" className="default-text-color" />
                  <div className="dropdown-icon-margin-left">
                    {t('Duplicate meeting')}
                  </div>
                </>
              </div>
            ),
            onClick: () => duplicateAndEditMeeting(room),
          }
        : null,
    ])
    menu = menu.concat(!room.archived ? [{ type: 'divider' }] : [])
    if (!room.archived) {
      menu.push({
        key: 'delete',
        label: (
          <div className="cursor-pointer d-flex d-flex-middle bin-button">
            <IoTrashOutline size="1.1rem" className="error-color" />
            <div className="dropdown-icon-margin-left error-color">
              {room.creator?.email === email
                ? t('Cancel meeting')
                : t('Leave the meeting')}
            </div>
          </div>
        ),
        onClick: () => openConfirmModal(room, false),
        className: 'mettings-dropdown-item delete-btn',
      })
    }

    return menu
  }

  const renderRoleList = (title: string, role: string, room: RoomInterface) => {
    const participants = (room.participants || []).concat(room.invited || [])
    const filteredParticipants = participants.filter((p) => p.role === role)

    return (
      filteredParticipants.length > 0 && (
        <>
          <b>{title}</b>
          <ul>
            {filteredParticipants.map((p) => (
              <li key={p.email}>
                {p.firstName && p.lastName ? `${p.firstName} ${p.lastName}` : p.email}
              </li>
            ))}
          </ul>
        </>
      )
    )
  }

  const renderUserIcon = (
    invited: RoomParticipant[],
    participants: RoomParticipant[],
  ) => {
    const totalParticipants = (invited?.length || 0) + (participants?.length || 0)
    return (
      totalParticipants > 0 && (
        <>
          <AiOutlineUser size="1.5em" className="default-text-color cursor-pointer" />
          {totalParticipants + 1}
        </>
      )
    )
  }

  const renderParticipantsWithAccess = (room: RoomInterface) => {
    const participantsWithAccess = (room.participants || [])
      .concat(room.invited || [])
      .filter((p) => p.role !== RoomParticipantRoleEnum.Attendee)

    return participantsWithAccess.length > 0 ? (
      participantsWithAccess.map((p) => <li key={p.email}>{p.email}</li>)
    ) : (
      <div>{t('No participants')}</div>
    )
  }

  const columns: ColumnsType<RoomInterface> = [
    {
      className: 'type-meeting-column table-centered',
      render: (room: RoomInterface) =>
        room.type === RoomType.Phone ? (
          <SlPhone size="1.4em" className="default-text-color" />
        ) : room.type === RoomType.InPerson ? (
          <HiOutlineBuildingOffice2 size="1.5em" className="default-text-color" />
        ) : (
          <RiComputerLine size="1.5em" className="default-text-color" />
        ),
    },
    {
      title: t('Date'),
      className: `date-column showOnLaptop ${archived && 'no-background'}`,
      render: (room: RoomInterface) =>
        room.startDate
          ? localFormatDate(dayjs(room.startDate))
          : t('Date is not set', { ns: 'common' }),
      sorter: (a: RoomInterface, b: RoomInterface) => {
        if (!a.startDate && !b.startDate) {
          return 0
        }
        if (!a.startDate) {
          return -1
        }
        if (!b.startDate) {
          return -1
        }
        return dayjs.duration(dayjs(a.startDate).diff(dayjs(b.startDate))).asMinutes()
      },
      defaultSortOrder: archived ? 'descend' : 'ascend',
    },
    {
      title: t('Duration'),
      className: 'duration-column',
      render: (room: RoomInterface) =>
        humanizeDuration(dayjs.duration({ minutes: room.duration }).asMilliseconds(), {
          language: i18n.language,
        }),
    },
    {
      title: t('Meeting'),
      className: 'name-column',
      render: (room: RoomInterface) => unescape(room.name || ''),
    },
    {
      title: t('Created by'),
      className: 'creator-column',
      render: (room: RoomInterface) => (
        <Tooltip title={room.creator?.email}>
          {room.creator?.firstName} {room.creator?.lastName}
        </Tooltip>
      ),
    },
    {
      title: <span className="hide-on-small">{t('Participants')}</span>,
      className: 'participants-column',
      render: (room: RoomInterface) => (
        <Popover
          className="d-flex d-flex-middle w-min"
          content={() => (
            <div className="d-flex d-flex-column overflow-scroll mh-12rem pr-05rem w-13rem">
              {room.creator && (
                <>
                  <b>{t('Creator', { ns: 'common' })}</b>
                  <ul>
                    <li key={room.creator.email}>
                      {room.creator.firstName} {room.creator.lastName}
                    </li>
                  </ul>
                </>
              )}

              {renderRoleList(
                t('Proofreader'),
                RoomParticipantRoleEnum.Proofreader,
                room,
              )}

              {renderRoleList(t('Document'), RoomParticipantRoleEnum.Document, room)}

              {renderRoleList(t('Attendee'), RoomParticipantRoleEnum.Attendee, room)}
            </div>
          )}
        >
          {room.invited &&
            room.participants &&
            renderUserIcon(room.invited, room.participants)}
        </Popover>
      ),
    },
    {
      title: <span className="hide-on-small">{t('Documents', { ns: 'common' })}</span>,
      className: 'documents-column',
      render: (room: RoomInterface) => {
        const paperclip = (room.files?.length || 0) > 0 && (
          <AiOutlinePaperClip
            size="1.5em"
            className={
              !(room.participants || [])
                .concat(room.invited || [])
                .find((p) => p.role != RoomParticipantRoleEnum.Attendee)
                ? 'error-color cursor-pointer d-flex d-flex-middle'
                : 'default-text-color cursor-pointer d-flex d-flex-middle '
            }
          />
        )
        return room.creator?.email === email || hasAccessFiles(room) ? (
          <Popover
            content={() => (
              <div className="d-flex d-flex-column overflow-scroll mh-12rem pr-05rem w-18rem">
                <b>{t('Documents', { ns: 'common' })}</b>
                <ul>
                  {(room.files || []).map((file) => (
                    <li
                      className="mb-0 popover-documents"
                      key={file.uid}
                      onClick={() => {
                        dispatch(
                          fetchFileMetadata({
                            uid: file.uid,
                            encryptKey: room?.decrypt_key || '',
                          }),
                        )
                      }}
                    >
                      {file.filename}
                    </li>
                  ))}
                </ul>

                <b>{t('Participants who have access to documents')}</b>
                <ul>{renderParticipantsWithAccess(room)}</ul>
              </div>
            )}
          >
            {paperclip}
          </Popover>
        ) : (
          <>{paperclip}</>
        )
      },
    },
    {
      title: '',
      className: 'action-column',
      render: (room: RoomInterface) => {
        return (
          <div className="action-main-container">
            <div className="action-custom-container">
              <div className="action-hover-container">
                {!archived && (
                  <div className="action-icons">
                    <div className="action-rounded-icon">
                      <Tooltip title={t('Join meeting')} className="cursor-pointer link">
                        <div
                          className="join-meeting-link"
                          onClick={() =>
                            customMeetingInteraction(room, (room: RoomInterface) =>
                              navigate(`/room/${room.id}`),
                            )
                          }
                        >
                          <AiOutlineVideoCameraAdd size="1.5rem" />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <Dropdown
              menu={{ items: menuItems(room) }}
              className="action-more"
              trigger={['click']}
            >
              <div className="action-more-container">
                <div className="action-more">
                  <BiDotsVerticalRounded size="1.5em" />
                </div>
              </div>
            </Dropdown>

            <div className="action-more-mobile">
              {!archived && (
                <Button
                  type="primary"
                  onClick={() =>
                    customMeetingInteraction(room, (room: RoomInterface) =>
                      navigate(`/room/${room.id}`),
                    )
                  }
                >
                  <AiOutlineVideoCameraAdd size="1.1rem" />
                </Button>
              )}
              <Button
                onClick={() =>
                  customMeetingInteraction(room, (room) => setRoomModify(room))
                }
              >
                {(room.creator ? room.creator.email !== email || archived : false) ? (
                  <AiOutlineEye size="1.1rem" />
                ) : (
                  <AiOutlineEdit size="1.1rem" />
                )}
              </Button>
              <Button
                className="btn-danger-border"
                onClick={() => dispatch(setRoomToDelete({ room, pending: false }))}
              >
                <IoTrashOutline size="1.1rem" className="error-color" />
              </Button>
            </div>
          </div>
        )
      },
    },
  ]

  if (data.fetchStatus === 'loading') {
    return (
      <div className="d-flex d-flex-center d-flex-middle 2rem">
        <Spin size="large" />
      </div>
    )
  } else if (data.fetchStatus === 'success') {
    if (data.rooms.length > 0)
      return (
        <Table
          dataSource={data.rooms}
          rowKey="id"
          columns={columns}
          showSorterTooltip={false}
          pagination={
            data.rooms.length >= 21 && {
              position: ['bottomCenter'],
              showTotal: (total, range) => (
                <Trans
                  ns="common"
                  i18nKey="TABLE_PAGINATION"
                  values={{
                    range0: range[0],
                    range1: range[1],
                    total: total,
                  }}
                />
              ),
              showSizeChanger: true,
              defaultPageSize: 20,
            }
          }
        />
      )
    else
      return (
        <p>{archived ? t('No archived meetings yet') : t('No upcoming meetings yet')}</p>
      )
  } else if (data.fetchStatus === 'error') {
    if (data.fetchError === '401')
      return (
        <Alert
          message={t('An error occurred. Please try to log out and log in.')}
          type="error"
        />
      )
    else if (data.fetchError === 'SERVICE_UNAVAILABLE') return <ServiceUnavailableError />
    else
      return (
        <Alert
          message={t('An error occurred. Please try later.', { ns: 'common' })}
          type="error"
        />
      )
  } else
    return (
      //shouldn't happend!
      <>something went wrong</>
    )
}
