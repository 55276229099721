import React, { useState } from 'react'
import { Layout, Typography, Tooltip } from 'antd'
import { authLogoFilename, backgroundImageFilename } from '../../assets/personnalization'
import NotarisationModal from '../../components/BadgesModal/NotarisationModal'
import DigitalSovereigntyModal from '../../components/BadgesModal/DigitalSovereigntyModal'
import { useTranslation } from 'react-i18next'
import packageInfo from '../../../package.json'
import './Maintenance.scss'
import { IoSettingsOutline } from 'react-icons/io5'

const { Title, Text } = Typography
const { Content } = Layout

function Maintenance() {
  const { i18n, t } = useTranslation('maintenance')

  const currentYear = new Date().getFullYear()
  const [openNotarisation, setOpenNotarisation] = useState(false)
  const [openSovereignty, setOpenSovereignty] = useState(false)
  return (
    <div
      className="Maintenance"
      style={{ backgroundImage: `url(/images/${backgroundImageFilename})` }}
    >
      <div className="documents-banner">
        <div>Auxamed {currentYear}</div>
        <div className="badges-container">
          <div className="sovereignty-container" onClick={() => setOpenSovereignty(true)}>
            <img src={`/images/sovereignty_${i18n.language}.png`} />
          </div>
          <div
            className="notarisation-container"
            onClick={() => setOpenNotarisation(true)}
          >
            <img src={`/images/notarisation_${i18n.language}.png`} />
          </div>
        </div>
      </div>
      <div className="layer" />
      <Tooltip
        title={`${t('Version:', { ns: 'common' })} ${packageInfo.version}`}
        placement="bottom"
      >
        <img className="logo" src={`/logo/${authLogoFilename}`} />
      </Tooltip>
      <div className="container">
        <IoSettingsOutline className="icon" size="3em" />
        <h1>{t('Maintenance in progress...')}</h1>
        <h3>{t("We're currently undergoing maintenance to improve your experience.")}</h3>
        <h3>
          {t(
            "Please bear with us while we make these enhancements. We'll be back shortly. Thank you for your patience!",
          )}
        </h3>
      </div>
      <NotarisationModal
        openNotarisation={openNotarisation}
        setOpenNotarisation={setOpenNotarisation}
      />

      <DigitalSovereigntyModal
        openSovereignty={openSovereignty}
        setOpenSovereignty={setOpenSovereignty}
      />
    </div>
  )
}

export default Maintenance
