import React from 'react'
import modal from 'antd/es/modal'
import { unescape } from 'html-escaper'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  deletePeriodicRoom,
  deleteGeneratedRoom as fetchDeleteGeneratedRoom,
  deleteRoom as fetchDeleteRoom,
  setRoomToDelete,
} from '../../features/Meetings/MeetingsSlice'
import { PendingRoomInterface, RoomInterface } from '../../features/room/RoomInterface'
import { AppDispatch, RootState } from '../../store'
import { limitText } from '../../utils/Utils'

export default function useModalDeleteMeeting() {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const email = useSelector((state: RootState) => state.auth.email)
  const deleteRoomStatus = useSelector((state: RootState) => state.rooms.deleteRoomStatus)
  const deleteGeneratedRoomStatus = useSelector(
    (state: RootState) => state.rooms.deleteGeneratedRoomStatus,
  )
  const deletePeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.deletePeriodicRoomStatus,
  )

  function onConfirmed(
    roomToDelete: RoomInterface | PendingRoomInterface,
    pending: boolean,
  ) {
    if (roomToDelete?.periodicRoomId) {
      dispatch(
        fetchDeleteGeneratedRoom({
          periodicRoomId: roomToDelete?.periodicRoomId || '',
          date: roomToDelete?.startDate || '',
          id: roomToDelete?.id || '',
        }),
      )
    } else if (roomToDelete?.periodicity) {
      dispatch(deletePeriodicRoom({ roomId: roomToDelete.id || '' }))
    } else {
      dispatch(
        fetchDeleteRoom({
          roomId: roomToDelete?.id || '',
          pending,
        }),
      )
    }
  }

  const openConfirmModal = (
    roomToDelete: RoomInterface | PendingRoomInterface,
    pending: boolean = false,
  ) => {
    const isCancel = roomToDelete?.creator?.email === email && !roomToDelete?.archived
    const nbParticipants =
      (roomToDelete?.participants?.length || 0) +
      (roomToDelete?.invited?.length || 0) +
      (roomToDelete?.creator ? 1 : 0)

    modal.confirm({
      title: isCancel ? t('Cancel meeting') : t('Leave the meeting'),
      icon: <></>,
      centered: true,
      content: (
        <>
          <p>
            <Trans
              i18nKey={isCancel ? 'CANCEL_MEETING' : 'LEAVE_MEETING'}
              shouldUnescape={true}
              ns="meetings"
              values={{
                meetingTitle: unescape(limitText(roomToDelete?.name)),
              }}
              components={{ bold: <strong /> }}
            />
          </p>
          {isCancel && nbParticipants > 0 && (
            <p>
              <Trans
                i18nKey="MEETING_PARTICIPANTS"
                ns="meetings"
                values={{ nbParticipants }}
                components={{ bold: <strong /> }}
              />
            </p>
          )}
        </>
      ),
      onOk: () => onConfirmed(roomToDelete, pending),
      okText: t('Yes', { ns: 'common' }),
      okButtonProps: {
        loading:
          deleteRoomStatus === 'loading' ||
          deleteGeneratedRoomStatus === 'loading' ||
          deletePeriodicRoomStatus === 'loading',
      },
      onCancel: () => dispatch(setRoomToDelete({ room: undefined, pending: false })),
      cancelText: t('No', { ns: 'common' }),
    })
  }
  return { openConfirmModal }
}
