import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import Notifier from './Notifier'
import { useEffect, useRef } from 'react'
import { attemptFetchLiveNotifications } from './liveWidgetSlice'

export function useNotifier() {
  const dispatch = useDispatch<AppDispatch>()
  const jwt = useSelector((state: RootState) => state.auth.jwt)
  const email = useSelector((state: RootState) => state.auth.email)
  const connected = useSelector((state: RootState) => state.liveNotification.connected)
  const notifier = useRef<Notifier | null>(null)

  /**
   *
   */
  useEffect(() => {
    if (!jwt || !email) return

    dispatch(attemptFetchLiveNotifications())
    if (!notifier.current) {
      notifier.current = new Notifier(dispatch, jwt, email)
    }
    if (!connected) {
      notifier.current.connect()
    }
  }, [jwt])

  return notifier
}
