import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { ChatWebSocketConnectionContext } from '../../components/ChatComponent/WebsocketConnection/ChatWebSocketConnectionContext'

export default function useScrollToMessage(
  chatId: string,
  squadId: string,
): (messageId: string) => void {
  const ws = useContext(ChatWebSocketConnectionContext)

  const targetMessageRef = useRef<string | undefined>()
  const selectChunks = (state: RootState) => state.chats.chatsMessages[chatId].chunks
  const oldestLoadedChunkSelector = createSelector(selectChunks, (chunks) => {
    const keys = Object.keys(chunks)
    return Math.min(...keys.map(Number))
  })

  const oldestLoadedChunk = useSelector(oldestLoadedChunkSelector)

  const chatMessages = useSelector(
    (state: RootState) => state.chats.chatsMessages[chatId],
  )

  useEffect(() => {
    if (!targetMessageRef.current) return
    const originalMsg = document.getElementById(targetMessageRef.current)

    if (originalMsg)
      setTimeout(() => {
        //TODO: refactor!!!!
        startScrolling(originalMsg)
      }, 200)
  }, [chatMessages])

  requestAnimationFrame(() => {
    if (!targetMessageRef.current) return
    const originalMsg = document.getElementById(targetMessageRef.current)
    if (originalMsg) startScrolling(originalMsg)
  })

  function isElementVisible(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect()
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth

    const elementIsVisible =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= windowHeight &&
      rect.right <= windowWidth

    return elementIsVisible
  }

  function startScrolling(originalMsg: HTMLElement) {
    targetMessageRef.current = undefined

    if (!isElementVisible(originalMsg)) {
      originalMsg.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    originalMsg.classList.add('msg-highlighted')

    setTimeout(() => {
      originalMsg.classList.remove('msg-highlighted')
    }, 3000)
  }

  function scrollToMessage(messageId: string) {
    targetMessageRef.current = messageId

    const originalMsg = document.getElementById(messageId)
    if (originalMsg) {
      startScrolling(originalMsg)
    } else {
      ws?.sendFetchMessagesUntil(squadId, chatId, messageId, oldestLoadedChunk)
    }
  }

  return scrollToMessage
}
