import React, { useContext } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { getChatDetails } from '../../../features/room/RoomChat/ChatLibrary'
import { Chat, setCurrentChatId, setChatToModify, setCalling } from './chatsSlice'
import { Button, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { useTranslation } from 'react-i18next'
import './ChatTitle.scss'
import { FiUser, FiUsers } from 'react-icons/fi'
import { ChatWebSocketConnectionContext } from '../WebsocketConnection/ChatWebSocketConnectionContext'
import { TbListTree } from 'react-icons/tb'
import { IoVideocamOutline } from 'react-icons/io5'

interface Props {
  currentSquadId: string
  chat: Chat
  canUnselectChat: boolean
  setOpenResponsiveDrawerMenu?: (open: boolean) => void
}

function ChatTitle({
  currentSquadId,
  chat,
  canUnselectChat,
  setOpenResponsiveDrawerMenu,
}: Props) {
  const { t } = useTranslation('chat')
  const dispatch = useDispatch<AppDispatch>()
  const ws = useContext(ChatWebSocketConnectionContext)
  const { name, icon, backgroundColor } = getChatDetails(chat)
  const peersStatuses = useSelector(
    (state: RootState) => state.chatSquads.peersStatuses[currentSquadId],
  )

  // const isScrolledToBottom = useSelector((state: RootState) => state.chats.currentChat)
  //   ?.scrolledToBottom

  return (
    <>
      <div className="chat_title">
        {setOpenResponsiveDrawerMenu && (
          <Button
            className="drawer-menu-responsive-mobile"
            onClick={() => setOpenResponsiveDrawerMenu(true)}
          >
            <TbListTree width="5rem" />
          </Button>
        )}

        {canUnselectChat && (
          <div className="d-flex d-flex-middle mr-1rem">
            <AiOutlineArrowLeft
              onClick={() => {
                dispatch(setCurrentChatId(undefined))
                // isScrolledToBottom && dispatch(markMessagesRead(chat.id))
              }}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
        <div className="d-flex d-flex-middle h-3rem">
          <div className="chat_title_avatar_container">
            <div className="chat_title_avatar" style={{ backgroundColor }}>
              {icon}
            </div>
            {chat.peerToPeer &&
              chat.peer &&
              peersStatuses &&
              peersStatuses[chat.peer.email] === 'ONLINE' && (
                <div className="chat_item_p2p_online" />
              )}
          </div>

          <Tooltip title={chat.peerToPeer ? chat.peer?.email : null}>
            <span className="chat-label">{t(name, { ns: 'meetings' })}</span>
          </Tooltip>
        </div>
      </div>
      <div className="chat_header_separator"></div>
      <div>
        {/* {chat.peerToPeer && (
          <Button type="primary" onClick={() => dispatch(setCalling(true))}>
            <IoVideocamOutline />
          </Button>
        )} */}
        {!chat.everybody && !chat.peerToPeer && (
          <Button size="small" onClick={() => dispatch(setChatToModify(chat))}>
            <Tooltip title={t('Users', { ns: 'room' })}>
              <span className="mr-05em">{chat.peers.length}</span>
              <FiUser />
            </Tooltip>

            {chat.groups.length > 0 && (
              <Tooltip title={t('Groups', { ns: 'common' })}>
                <span className="ml-05em">|</span>
                <span className="ml-05em mr-05em">{chat.groups.length}</span>
                <FiUsers />
              </Tooltip>
            )}
          </Button>
        )}
      </div>
      {/* {!canUnselectChat && chat.peerToPeer && (
        <Tooltip title={t('Archive chat')}>
          <Button
            className="chat_archive_btn"
            icon={<IoArchive />}
            onClick={() => ws?.sendArchiveChat(chat.id)}
          />
        </Tooltip>
      )} */}
    </>
  )
}

export default ChatTitle
