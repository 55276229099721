import React, { useState } from 'react'
import { Button, Drawer, Menu } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from '../../store'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import {
  AiOutlineClockCircle,
  AiOutlineInbox,
  AiOutlineMenu,
  AiOutlineUnorderedList,
  AiOutlineVideoCameraAdd,
} from 'react-icons/ai'
import { VscAccount } from 'react-icons/vsc'
import { LanguageSwitch } from '../LanguageSwitch'
import { FiPower } from 'react-icons/fi'
import { logout } from '../../features/auth/redux/authSlice'
import { buildCalendarUrl } from '../../features/calendar/CalendarService'
import './MobileMenu.scss'
import { LOGO } from '../../assets/logos'

function MobileMenu() {
  const { t, i18n } = useTranslation('layout')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const auth = useSelector((state: RootState) => state.auth)
  const currentYear = new Date().getFullYear()

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  function logoutHandler() {
    dispatch(logout())
    navigate('/login')
  }

  function goToCalendar() {
    navigate(buildCalendarUrl(dayjs().toString(), 'week'))
  }

  const items: any = [
    {
      key: 'unused',
      label: (
        <span className="d-flex d-flex-column title-header">
          <span className="text-one-line">{`${auth.firstName} ${auth.lastName}`}</span>
        </span>
      ),
    },
    {
      key: 'Meetings',
      className: 'mobile-menu mobile-menu-meetings',
      label: (
        <>
          Meetings
          <div className="clickable-submenu" />
        </>
      ),
      icon: <img src={LOGO.logo_auxameetings} className="mobile-menu-logo" />,
      children: [
        {
          key: 'new-meeting',
          label: t('New meeting'),
          icon: <AiOutlineVideoCameraAdd />,
          onClick: () => navigate('/new-meeting'),
        },
        {
          key: 'upcoming-meetings',
          label: t('Upcoming meetings'),
          icon: <AiOutlineUnorderedList />,
          onClick: () => navigate('/upcoming-meetings'),
        },
        {
          key: 'pending-meetings',
          label: t('Pending meetings'),
          icon: <AiOutlineClockCircle />,
          onClick: () => navigate('/pending-meetings'),
        },
        {
          key: 'archived-meetings',
          label: t('Archived meetings'),
          icon: <AiOutlineInbox />,
          onClick: () => navigate('/archived-meetings'),
        },
      ],
    },
    {
      key: 'Planning',
      label: 'Planning',
      className: 'mobile-menu mobile-menu-planning',
      icon: <img src={LOGO.logo_auxaplanning} className="mobile-menu-logo" />,
      onClick: goToCalendar,
    },
    {
      key: 'Team',
      label: 'Team',
      className: 'mobile-menu mobile-menu-team',
      icon: <img src={LOGO.logo_auxateam} className="mobile-menu-logo" />,
      onClick: () => navigate('/messages'),
    },
    {
      key: 'Drive',
      label: 'Drive',
      className: 'mobile-menu mobile-menu-drive',
      icon: <img src={LOGO.logo_auxadrive} className="mobile-menu-logo" />,
      onClick: () => navigate('/drives/home'),
    },
    {
      key: 'Transfer',
      label: 'Transfer',
      className: 'mobile-menu mobile-menu-transfer',
      icon: <img src={LOGO.logo_auxatransfer} className="mobile-menu-logo" />,
    },
    {
      type: 'divider',
    },
    {
      key: 'Organisation',
      label: t('Organisation', { ns: 'common' }),
      className: 'mobile-menu mobile-menu-default',
      icon: <img src={LOGO.logo_groups} className="mobile-menu-logo" />,
      children: [
        {
          key: 'Customer area',
          label: t('Customer area'),
          icon: <img src={'/logo/logo_customer_area.svg'} className="mobile-menu-logo" />,
        },
        {
          key: 'organization',
          label: t('Members', { ns: 'common' }),
          icon: <img src={LOGO.logo_users} className="mobile-menu-logo" />,
          onClick: () => navigate('/organization'),
        },
        {
          key: 'groups',
          label: t('Groups', { ns: 'common' }),
          icon: <img src={LOGO.logo_groups} className="mobile-menu-logo" />,
          onClick: () => navigate('/groups'),
        },
      ],
    },
    {
      key: 'Faq',
      label: 'FAQ',
      className: 'mobile-menu mobile-menu-default',
      icon: <img src={LOGO.FAQ} className="mobile-menu-logo" />,
      onClick: () => navigate('/faq'),
    },
    {
      key: 'Applications',
      label: t('Menu'),
      className: 'mobile-menu mobile-menu-default',
      icon: <img src={LOGO.menu} className="mobile-menu-logo" />,
      onClick: () => navigate('/applications'),
    },
  ]

  function renderDrawer() {
    return (
      <div className="general-burger-menu MobileMenu">
        <AiOutlineMenu className="styled-icon" size="2.5em" onClick={showDrawer} />
        <Drawer
          width="18rem"
          title={
            <div className="drawer-title" onClick={() => navigate('/account')}>
              <VscAccount size="1.2em" className="account-icon" />
              <span>{auth.firstName + ' ' + auth.lastName}</span>
            </div>
          }
          placement="right"
          onClose={onClose}
          open={open}
        >
          <div className="mobile-menu-language-container">
            <LanguageSwitch />
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={
              (location.pathname === '/new-meeting' && ['new-meeting']) ||
              (location.pathname.includes('upcoming-meetings') && [
                'upcoming-meetings',
              ]) ||
              // (location.pathname.includes('pending-meetings') && ['pending-meetings']) ||
              (location.pathname.includes('/archived-meetings') && [
                'archived-meetings',
              ]) ||
              (location.pathname === '/organization' && ['organization']) ||
              (location.pathname === '/groups' && ['groups']) ||
              (location.pathname === '/account' && ['account']) ||
              (location.pathname === '/calendar' && ['calendar']) ||
              (location.pathname === '/personalization' && ['personalization']) ||
              (location.pathname === '/drives' && ['drives']) ||
              []
            }
            defaultOpenKeys={
              location.pathname === '/organization' || location.pathname === '/groups'
                ? ['organization', 'meetings']
                : ['meetings']
            }
            items={items.slice(1)}
          />
          <div className="drawer-disconnect-container">
            <Button
              className="drawer-logout-btn btn-secondary"
              type="default"
              onClick={logoutHandler}
            >
              <FiPower />
              <span>{t('Log out')}</span>
            </Button>
          </div>
          <div className="menu-mobile-footer">
            Auxamed {currentYear} -&nbsp;
            <a
              className="footer-document"
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_STATIC_ASSETS_BASE_URL}/legal-notice_${i18n.language}.pdf`}
            >
              {t('Legal notice', { ns: 'common' })}
            </a>
            <span> - </span>
            <a
              className="footer-document"
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_STATIC_ASSETS_BASE_URL}/cgu_${i18n.language}.pdf`}
            >
              {t('Terms and conditions of service', { ns: 'common' })}
            </a>
          </div>
        </Drawer>
      </div>
    )
  }

  return <>{renderDrawer()}</>
}

export default MobileMenu
