import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import Modal from 'antd/lib/modal/Modal'
import { Link } from 'react-router-dom'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { authLogoFilename } from '../../../assets/personnalization'
import { LanguageSwitchPreview } from './LanguageSwitchPreview'

const { Option } = Select

interface Props {
  logo: string | undefined
}

function AuthPreview({ logo }: Props) {
  const [form] = Form.useForm()
  const { t, i18n } = useTranslation('auth')

  const [isReinitPasswordModalOpen, setIsReinitPasswordModalOpen] = useState(false)
  const [reinitPasswordForm] = Form.useForm()

  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false)
  const [signupForm] = Form.useForm()
  const currentYear = new Date().getFullYear()

  const openReinitPasswordModal = () => {
    setIsReinitPasswordModalOpen(true)
    reinitPasswordForm.setFieldValue('email', form.getFieldsValue()['email'])
  }

  const closeReinitPasswordModal = () => {
    setIsReinitPasswordModalOpen(false)
  }

  const closeSingnupModal = () => {
    setIsSignupModalOpen(false)
  }

  return (
    <>
      <div className="Auth">
        <div className="documents-banner">
          Auxamed {currentYear} -&nbsp;
          <span>{t('Legal notice', { ns: 'common' })}</span>
        </div>
        <div className="layer" />
        <Link to="/" className="logo-container">
          <img className="logo" src={logo ? logo : `/logo/${authLogoFilename}`} />
        </Link>
        <div className="auth-methods">
          <div className="auth-methods-login-password">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ email: '', password: '' }}
              autoComplete="off"
              scrollToFirstError={true}
            >
              <Form.Item
                label={<span className="login-label">{t('Email')}</span>}
                name="email"
                rules={[{ required: true, message: t('Please input your email') }]}
              >
                <Input prefix={<MailOutlined className="prefix-label-icon" />} />
              </Form.Item>

              <Form.Item
                label={<span className="login-label">{t('Password')}</span>}
                name="password"
                rules={[{ required: true, message: t('Please input your password') }]}
              >
                <Input.Password prefix={<LockOutlined className="prefix-label-icon" />} />
              </Form.Item>

              <Button type="primary" htmlType="submit" block>
                {t('Log in')}
              </Button>
            </Form>
            <div className="auth-misc-container">
              <Button type="text" onClick={openReinitPasswordModal}>
                {t('Forgot your password?')}
              </Button>
              <Button type="text" onClick={() => setIsSignupModalOpen(true)}>
                {t('Create your account')}
              </Button>
            </div>
            <div className="badges-container">
              <div className="sovereignty-container">
                <img src={`/images/sovereignty_${i18n.language}.png`} />
              </div>
              <div className="notarisation-container">
                <img src={`/images/notarisation_${i18n.language}.png`} />
              </div>
            </div>
          </div>

          <div className="auth-methods-separator" />
          <div className="auth-methods-secure_and_access">
            <div className="auth-methods-language">
              <LanguageSwitchPreview />
            </div>
            <p className="secure-text">
              <strong>
                {t('Log in')}
                <br />
                {t('with Secure & Access')}
              </strong>
            </p>
            <Button>{t('Reconnect to Secure & Access')}</Button>
          </div>
        </div>
      </div>

      <Modal
        centered
        wrapClassName="preview"
        title={t('Reinitialize your password')}
        open={isReinitPasswordModalOpen}
        onCancel={closeReinitPasswordModal}
        footer={
          <div className="reinit-password-modal-footer">
            <Button onClick={closeReinitPasswordModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>
            <Button type="primary" htmlType="submit" form="reinit-password-form">
              {t('Reinitialize')}
            </Button>
          </div>
        }
      >
        <Form id="reinit-password-form" autoComplete="off" form={reinitPasswordForm}>
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[{ required: true, message: t('Please input your email') }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        wrapClassName="preview"
        title={t('Sign up')}
        open={isSignupModalOpen}
        onCancel={closeSingnupModal}
        footer={
          <div className="signup-modal-footer">
            <Button onClick={closeSingnupModal}>{t('Cancel', { ns: 'common' })}</Button>
            <Button type="primary" htmlType="submit" form="signup-form">
              {t('Sign up')}
            </Button>
          </div>
        }
      >
        <Form
          id="signup-form"
          form={signupForm}
          autoComplete="off"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          scrollToFirstError={true}
        >
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[{ required: true, message: t('Please input your email') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[{ required: true, message: t('Please input your first name') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[{ required: true, message: t('Please input your last name') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Phone')}
            name="tel"
            rules={[{ required: true, message: t('Please input your phone') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Preferred language')}
            name="lang"
            rules={[
              { required: true, message: t('Please select your preferred language') },
            ]}
          >
            <Select popupClassName="preview">
              <Select.Option value="de">
                <span>Deutsch</span>
              </Select.Option>
              <Select.Option value="en">
                <span>English</span>
              </Select.Option>
              <Select.Option value="fr">
                <span>Français</span>
              </Select.Option>
              <Select.Option value="it">
                <span>Italiano</span>
              </Select.Option>
              <Select.Option value="uk">
                <span>Українська</span>
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="password"
            label={t('Password')}
            rules={[{ required: true, message: t('Please input your password') }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={t('Confirm Password')}
            dependencies={['password']}
            rules={[
              { required: true, message: t('Please confirm your password') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(t('The two passwords that you entered do not match')),
                  )
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AuthPreview
