import React, { useState } from 'react'
import { Popover, Tooltip, Drawer } from 'antd'
import { IoIosNotificationsOutline } from 'react-icons/io'
import { IoCheckmarkCircleOutline, IoTrashOutline } from 'react-icons/io5'
import { BsListColumnsReverse } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import NotificationList from './NotificationList'
import './NotificationWidget.scss'
import { useLiveNotifications } from './useLiveNotifications'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  attemptDeleteAllNotifications,
  attemptMarkAllNotificationsSeen,
} from './liveWidgetSlice'

function NotificationWidget() {
  const { unseen } = useLiveNotifications()
  const [drawerVisible, setDrawerVisible] = useState(false)

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible)
  }

  return (
    <>
      <Popover
        content={<Content />}
        trigger="click"
        color="#f6f6f6"
        placement="bottomRight"
        className="hideOnMobile"
        arrow={{ pointAtCenter: true }}
        align={{ offset: [21, 14] }}
      >
        <div className="notification_widget">
          <IoIosNotificationsOutline size="2em" />
          {unseen !== undefined && unseen > 0 && (
            <div className="notification_widget_unseen_count">{unseen}</div>
          )}
        </div>
      </Popover>
      <div className="notification_widget" onClick={toggleDrawer}>
        <IoIosNotificationsOutline size="2em" />
        {unseen !== undefined && unseen > 0 && (
          <div className="notification_widget_unseen_count">{unseen}</div>
        )}
        <Drawer
          width="40rem"
          title="Notifications"
          placement="right"
          onClose={toggleDrawer}
          open={drawerVisible}
        >
          <Content />
        </Drawer>
      </div>
    </>
  )
}

function Content() {
  const { t } = useTranslation('notification')
  const dispatch = useDispatch<AppDispatch>()
  const notifications = useSelector(
    (state: RootState) => state.liveNotification.notifications,
  )

  return (
    <div className="notification_widget_content">
      {notifications.length > 0 ? (
        <>
          <div className="notification_widget_content_actions">
            <Link to="/notifications" className="notification_widget_content_action">
              <BsListColumnsReverse />
              &nbsp;
              <span className="notification_widget_content_all">
                {t('See all notifications')}
              </span>
            </Link>

            <div className="mt-0_2rem">
              <Tooltip title={t('Mark all as read')}>
                <IoCheckmarkCircleOutline
                  onClick={() => dispatch(attemptMarkAllNotificationsSeen())}
                  className="notification_widget_content_action"
                />
              </Tooltip>

              <Tooltip title={t('Delete all')}>
                <IoTrashOutline
                  onClick={() => dispatch(attemptDeleteAllNotifications())}
                  className="notification_widget_content_action"
                />
              </Tooltip>
            </div>
          </div>
          {notifications.map((notification) => (
            <NotificationList notification={notification} key={notification.id} />
          ))}
        </>
      ) : (
        <div>{t('No notification')}</div>
      )}
    </div>
  )
}

export default NotificationWidget
