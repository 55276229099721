import React from 'react'
import { Drawer, Alert, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import {
  markMessagesRead,
  setCurrentChatId,
} from '../../../components/ChatComponent/Chat/chatsSlice'
import ChatTitle from '../../../components/ChatComponent/Chat/ChatTitle'
import CreateChat from '../../../components/ChatComponent/CreateChat/CreateChat'
import { ChatWebSocketConnectionContext } from '../../../components/ChatComponent/WebsocketConnection/ChatWebSocketConnectionContext'
import ChatDisconnectedAlert from '../../../components/ChatComponent/Chat/ChatDisconnectedAlert'
import './RoomChatDrawer.scss'
import Chat from '../../../components/ChatComponent/Chat/Chat'
import ChatList from '../../../components/ChatComponent/Chat/ChatList'
import useChatInitialization from '../../../utils/hooks/useChatInitialization'

interface RoomChatDrawerProps {
  currentSquadId: string
  messagesDrawerOpen: boolean
  onMessagesClose: () => void
}

function RoomChatDrawer({
  currentSquadId,
  messagesDrawerOpen,
  onMessagesClose,
}: RoomChatDrawerProps) {
  const { t } = useTranslation(['room', 'meetings', 'chat'])
  const jwt = useSelector((state: RootState) => state.auth.jwt || state.invite.jwt || '')
  const email = useSelector(
    (state: RootState) => state.auth.email || state.invite.email || '',
  )
  const { wsRef, squad, currentChatId, chats, chatStatus } = useChatInitialization({
    currentSquadId,
    jwt,
    email,
    selectDefaultChatOnLoad: false,
    chatMeeting: true,
  })
  const dispatch = useDispatch<AppDispatch>()
  const currentChat = useSelector((state: RootState) =>
    (state.chats.chats[currentSquadId] || []).find((c) => c.id === currentChatId),
  )

  const isScrolledToBottom = useSelector(
    (state: RootState) => state.chats.currentChat,
  )?.scrolledToBottom

  function unselectChat() {
    dispatch(setCurrentChatId(undefined))
  }

  return (
    <ChatWebSocketConnectionContext.Provider value={wsRef.current}>
      <Drawer
        title={
          <div className="chat_drawer_title">
            {currentChat ? (
              <ChatTitle
                currentSquadId={currentSquadId}
                chat={currentChat}
                canUnselectChat={true}
              />
            ) : (
              <div className="chat_list_title">
                <div className="mr-05em">{t('Messages')}</div>
              </div>
            )}
            <ChatDisconnectedAlert />
          </div>
        }
        placement="right"
        open={messagesDrawerOpen}
        onClose={() => {
          onMessagesClose()
          unselectChat()
          isScrolledToBottom && dispatch(markMessagesRead(currentChatId || ''))
        }}
        className="messages-drawer room-drawer"
        mask={false}
      >
        {chatStatus === 'ACTIVE' &&
          (currentChatId ? (
            <Chat currentSquadId={squad.id} currentChatId={currentChatId} />
          ) : (
            <ChatList currentSquadId={squad.id} inCall={true} />
          ))}
        {chatStatus === 'MISSING_SQUAD' && (
          <Alert type="error" message={t('Chat was not activated.')} className="m-1em" />
        )}
        {chatStatus === 'LOADING' && (
          <div className="d-flex d-flex-center d-flex-middle 2rem">
            <Spin size="large" />
          </div>
        )}
      </Drawer>
    </ChatWebSocketConnectionContext.Provider>
  )
}

export default RoomChatDrawer
