import { useEffect, useRef } from 'react'
import { ChatWebsocketConnection } from '../../components/ChatComponent/WebsocketConnection/ChatWebsocketConnection'

export default function useTabFocusWebSocketControl(
  wsRef = useRef<ChatWebsocketConnection | undefined>(),
) {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        wsRef.current?.disconnect(false)
      } else {
        wsRef.current?.connect()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [wsRef])
}
